<template>
	<div class="container">
		<div class="left-menu" :style="{
        width: !isCollapse ? '12%' : '4.5%',
        textAlign: !isCollapse ? 'left' : 'center',
      }">
			<left-list :leftIndex="'14'" :isCollapse="isCollapse"></left-list>
		</div>
		<div class="right-content" :style="{ width: !isCollapse ? '88%' : '95.5%' }">
			<div class="top-nav">
				<top-header @openMenu="openMenu" :isCollapse="isCollapse"></top-header>
			</div>
			<div class="left-list">
				<div class="left-list-title">
					<span>标准管理</span>
				</div>
				<div class="left-list-item left-list-active" @click="leftClick('0')">
					<span>标准列表</span>
				</div>
				<div class="left-list-item" @click="leftClick('1')">
					<span>标准分类</span>
				</div>
			</div>
			<div class="right-next">
				<div class="date-table">
					<div class="title">
						<span>{{title}}</span>
					</div>
					<div class="edit-form">
						<div class="item">
							<div class="label">标准分类:</div>
							<el-select v-model="editForm.type" clearable placeholder="请选择标准分类">
								<el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id">
								</el-option>
							</el-select>
						</div>
						<div class="item">
							<div class="label">标准名称:</div>
							<el-input v-model="editForm.corporateName" placeholder="标准名称"></el-input>
						</div>
						<div class="item">
							<!--  @blur="onEditorBlur($event)" @focus="onEditorFocus($event)"
								@ready="onEditorReady($event)" @change="onEditorChange($event)" -->
							<div class="label" style="vertical-align: top;">标准概括:</div>
							<textarea v-model="editForm.generalization" placeholder="标准概括"></textarea>
							<!-- <quill-editor class="editor" ref="myTextEditor" v-model="editForm.generalization"
								:options="editorOption">
							</quill-editor> -->
						</div>
						<div v-if="!this.$route.query.id" class="item">
							<div class="label">标准文件:</div>
							<el-upload class="avatar-uploader" action="url" :show-file-list="true" ref="upload"
								:on-change="uploadChange" :headers="uploadHeaders" :auto-upload="false"
								:http-request="uploadFile" accept=".pdf,.doc,.docx">
								<el-button size="small" type="primary">点击上传</el-button>
								<div slot="tip" class="el-upload__tip">只能上传pdf/doc/docx文件</div>
							</el-upload>
						</div>
						<div class="item submit">
							<el-button type="primary" @click="submitUpload" v-loading.fullscreen.lock="fullscreenLoading">保存添加</el-button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import leftList from "../../components/leftList.vue";
	import topHeader from "../../components/header.vue"
	export default {
		components: {
			leftList,
			topHeader
		},
		inject: ["reload"],
		data() {
			return {
				fullscreenLoading: false,
				options: [],
				title: '新增标准',
				isCollapse: false,
				uploadHeaders: {
					"Remember-Token": localStorage.getItem("rememberToken"),
				},
				editForm: {
					corporateName: '',
					url: '',
					generalization: '',
					type: '',
					size: ''
				},
				formDate: '',
				editorOption: {
					modules: {
						toolbar: [
							["bold", "italic", "underline", "strike"], // 加粗 斜体 下划线 删除线
							["blockquote", "code-block"], // 引用  代码块
							[{
								header: 1
							}, {
								header: 2
							}], // 1、2 级标题
							[{
								list: "ordered"
							}, {
								list: "bullet"
							}], // 有序、无序列表
							[{
								script: "sub"
							}, {
								script: "super"
							}], // 上标/下标
							[{
								indent: "-1"
							}, {
								indent: "+1"
							}], // 缩进
							// [{'direction': 'rtl'}],                         // 文本方向
							[{
								size: ["small", false, "large", "huge"]
							}], // 字体大小
							[{
								header: [1, 2, 3, 4, 5, 6, false]
							}], // 标题
							[{
								color: []
							}, {
								background: []
							}], // 字体颜色、字体背景颜色
							[{
								font: []
							}], // 字体种类
							[{
								align: []
							}], // 对齐方式
							["clean"], // 清除文本格式
							["link"] // 链接、图片、视频
						], //工具菜单栏配置
					},
					placeholder: '请输入内容', //提示
					readyOnly: false, //是否只读
					theme: 'snow', //主题 snow/bubble
					syntax: true, //语法检测
				}
			};
		},
		created() {
			if (this.$route.query.id) {
				this.title = '编辑文件';
				this.getinfo();
			}
			this.getList();
		},
		methods: {
			getinfo() {
				this.$get('standard/get_details', {
					id: this.$route.query.id
				}).then(res => {
					this.editForm.type = res.data.data.type_id;
					this.editForm.corporateName = res.data.data.name;
					this.editForm.generalization = res.data.data.details;
          this.editForm.url = res.data.data.url;
				})
			},
			getList() {
				this.$get('standard/category/get_list', {
					page: 1,
					num: 999
				}).then(res => {
					this.options = res.data.data.data;
				})
			},
			submitUpload() {
        let _this = this;
				if (this.editForm.type == "") {
					this.$notify.error({
						title: "提示",
						message: "请选择标准分类！",
					});
					return false;
				}
				if (this.editForm.corporateName == "") {
					this.$notify.error({
						title: "提示",
						message: "请填写标准名称！",
					});
					return false;
				}
				if (this.editForm.generalization == '') {
					this.$notify.error({
						title: "提示",
						message: "请填写标准概括！",
					});
					return false;
				}
				if (this.editForm.url == '') {
					this.$notify.error({
						title: "提示",
						message: "请上传标准文件！",
					});
					return false;
				}
				this.fullscreenLoading = true;
				this.formDate = new FormData();
        // eslint-disable-next-line no-empty
        if(this.$route.query.id > 0){
        }else{
          _this.$refs.upload.submit();
          // eslint-disable-next-line no-mixed-spaces-and-tabs
				  this.formDate.append("size", this.editForm.size);
        }
				this.formDate.append("type_id", this.editForm.type);
				this.formDate.append("name", this.editForm.corporateName)
        if(this.editForm.generalization !== null){
          this.formDate.append("details", this.editForm.generalization);
        }

				let config = {
					headers: {
						"Content-Type": "multipart/form-data",
					},
				};
				if (this.$route.query.id) {
					this.formDate.append("id", this.$route.query.id);
					this.$axios
						.post(this.$store.state.httpUrl + "upd/standard", this.formDate, config)
						.then((res) => {
							this.fullscreenLoading = false;
							if (res.data.status == 200) {
								this.$notify({
									title: "提示",
									message: "操作成功！",
									type: "success",
								});
								this.$router.push("/standard");
							} else {
								this.$notify.error({
									title: "提示",
									message: res.data.message,
								});
							}
							// console.log(res);
						})
						.catch((res) => {
							this.fullscreenLoading = false;
							console.log(res);
						});
				} else {
					this.$axios
						.post(this.$store.state.httpUrl + "add/standard", this.formDate, config)
						.then((res) => {
							this.fullscreenLoading = false;
							if (res.data.status == 200) {
								this.$notify({
									title: "提示",
									message: "操作成功！",
									type: "success",
								});
								this.$router.push("/standard");
							} else {
								this.$notify.error({
									title: "提示",
									message: res.data.message,
								});
							}
							// console.log(res);
						})
						.catch((res) => {
							this.fullscreenLoading = false;
							console.log(res);
						});
				}
			},
			uploadFile(file) {
				this.formDate.append("files", file.file);
				// console.log(this.formDate);
			},
			uploadChange(file, fileList) {
				console.log(file, fileList);
				this.editForm.size = parseFloat(file.size / 1024 / 1024).toFixed(2);
				this.editForm.url = URL.createObjectURL(file.raw);
			},
			openMenu() {
				this.isCollapse = !this.isCollapse;
			},
			leftClick(index) {
				if (index == 0 && this.$route.path != "/standard") {
					this.$router.push("/standard");
				} else if (index == 1 && this.$route.path != "/standardtype") {
					this.$router.push("/standardtype");
				} else {
					this.reload();
				}
			},
		},
	};
</script>

<style lang="scss">
	.quill-editor {
		margin-top: 20px;
		width: 80%;
	}

	.ql-editor {
		height: 230px;
	}

	.avatar-uploader {
		display: inline-block;
		vertical-align: top;
	}

	.avatar {
		width: 178px;
		height: 178px;
		// display: block;
	}

	.right-content {
		height: 100vh;
		overflow-x: hidden;
		overflow-y: scroll;
		vertical-align: top;
		display: inline-block;

		&::-webkit-scrollbar {
			display: none;
		}

		.right-next {
			height: 93vh;
			width: 88.5%;
			overflow-x: hidden;
			overflow-y: scroll;
			border-top: 20px solid #f2f2f2;
			padding: 20px;
			box-sizing: border-box;
			display: inline-block;
			vertical-align: top;

			&::-webkit-scrollbar {
				display: none;
			}
		}

		.left-list {
			width: 10%;
			height: 93vh;
			overflow-x: hidden;
			overflow-y: scroll;
			font-size: 14px;
			border-right: 20px solid #f2f2f2;
			display: inline-block;
			text-align: center;

			&::-webkit-scrollbar {
				display: none;
			}

			&-title {
				margin: 20px 0;
				font-size: 16px;
			}

			&-item {
				padding: 15px 0;
				cursor: pointer;
				transition: all 0.2s;

				&:hover {
					color: #fff;
					background-color: #3291f8;
				}
			}

			&-active {
				color: #fff;
				background-color: #3291f8;
			}
		}

		.date-table {
			margin-bottom: 20px;

			.operation {
				margin-top: 30px;

				.el-button {
					width: 100px;
				}
			}
		}

		.title {
			color: #000;
			height: 10px;
			padding-left: 15px;
			line-height: 10px;
			border-left: 5px solid #80c4f8;
			margin-bottom: 20px;
		}

		.edit-form {
			.submit {
				width: 80%;
				text-align: center;

				.el-button {
					width: 150px;
				}
			}

			.item {
				margin-top: 30px;

				.label {
					display: inline-block;
					vertical-align: middle;
					width: 120px;
				}

				textarea {
					-webkit-appearance: none;
					background-color: #FFF;
					background-image: none;
					border-radius: 4px;
					border: 1px solid #DCDFE6;
					box-sizing: border-box;
					color: #606266;
					display: inline-block;
					font-size: inherit;
					height: 150px;
					outline: 0;
					padding: 10px;
					transition: border-color .2s cubic-bezier(.645, .045, .355, 1);
					width: 230px;
					resize: none;

					&:focus {
						border-color: #409EFF;
					}
				}

				.el-input {
					width: 230px;
				}
			}
		}

		.top-nav {
			display: flex;
			box-sizing: border-box;
			justify-content: space-between;
			padding: 15px;

			.left-icon {
				i {
					cursor: pointer;
					color: #3291f8;
					font-size: 30px;
				}

				.el-icon-refresh-right {
					margin-left: 30px;
				}
			}

			.right-user {
				font-size: 14px;

				span {
					vertical-align: middle;
					margin-right: 20px;
				}

				.colse-btn {
					cursor: pointer;
					vertical-align: middle;
					border: 1px solid #999;
					display: inline-block;
					padding: 5px 20px;
					border-radius: 50px;

					span {
						margin-right: 10px;
					}

					i {
						vertical-align: middle;
					}
				}
			}
		}
	}

	.left-menu {
		display: inline-block;

		.iconyonghuzu {
			font-size: 18px;
			margin: 0 5px;
		}
	}

	.el-menu {
		width: 100%;
		background-color: #20222a;
		height: 100vh;
	}

	.el-menu-vertical-demo:not(.el-menu--collapse) {
		background-color: #20222a;
		color: #fff;
		height: 100vh;
		width: 100%;
	}

	.el-menu-item:focus,
	.el-menu-item:hover {
		color: #000;
		background-color: #ecf5ff;
	}

	.el-menu-item {
		color: #fff;

		i {
			color: #3291f8;
		}
	}

	.el-menu-item.is-active {
		background-color: #ecf5ff;
		color: #20222a;
	}

	.menu-h5 {
		margin: 20px;
		text-align: center;
		font-size: 20px;
	}

	.menu-icon {
		width: 100%;
		color: #fff;
		box-sizing: border-box;
		padding: 30px 22px;
	}
</style>
